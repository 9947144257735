<template>
  <div class="body">
    <div class="home">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>
          <img src="../../assets/img/banner.png" alt="" width="100%">
        </van-swipe-item>
        <van-swipe-item>
          <a href="https://mp.weixin.qq.com/s/reN0ug2HK-cVaO7Hyt8sww">
            <img src="../../assets/img/operator.png" alt="" width="100%">
          </a>
        </van-swipe-item>
      </van-swipe>
      <div class="add-button" @click="routeTo('/patient/-1')">
        <van-icon name="add-o" class="icon-add" />添加就诊人
        <img src="../../assets/img/shizi.png" alt="">
      </div>
      <div class="home-in">
        <div class="entry-list">
          <div class="entry-item" @click="routeTo('/appoint')">
            <img class="img" :src="require('../../assets/img/icon01.png')">
            <div>在线挂号</div>
          </div>
          <div class="entry-item" @click="routeTo('/expenselist')">
            <img class="img" :src="require('../../assets/img/icon02.png')">
            <div>在线缴费</div>
          </div>
          <div class="entry-item" @click="routeTo('/consultation')">
            <img class="img" :src="require('../../assets/img/icon03.png')">
            <div>签到/候诊</div>
          </div>
          <div class="entry-item" @click="routeTo('/patient')">
            <img class="img" :src="require('../../assets/img/icon04.png')">
            <div>就诊人管理</div>
          </div>
        </div>
        <div class="card-box">
          <div v-for="item in allServe" :key="item.name" class="item" @click="routeTo(item.url)">
            <img class="img" :src="item.imgUrl">
            <div class="txt">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="row-box">
          <div class="item" @click="routeTo('/hospitalIntroduction')">
            <img src="../../assets/img/yy.png" alt="">
            <div class="txt">
              医院介绍
            </div>
          </div>
          <div class="item" @click="kefu">
            <img src="../../assets/img/kf.png" alt="">
            <div class="txt">
              联系客服
            </div>
          </div>
          <div class="item" @click="routeTo('/hospitalRoute')">
            <img src="../../assets/img/luxian.png" alt="">
            <div class="txt">
              来院路线
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      allServe: [
        {
          name: '我的预约',
          url: '/myAppointment',
          imgUrl: require('../../assets/img/serv003.png')
        },
        {
          name: '我的挂号',
          url: '/myRegister',
          imgUrl: require('../../assets/img/serv004.png')
        },
        {
          name: '我的问诊',
          url: '/consultation',
          imgUrl: require('../../assets/img/wenz.png')
        },
        {
          name: '我的处方',
          url: '/prescription',
          imgUrl: require('../../assets/img/serv002.png')
        },
        {
          name: '我的订单',
          url: '/orderList',
          imgUrl: require('../../assets/img/order.png')
        },
        {
          name:'检验报告',
          url:'/checkout',
          imgUrl:require('../../assets/img/jiany.png')
        },
        {
          name:'检查报告',
          url:'/checkup',
          imgUrl:require('../../assets/img/jianc.png')
        },
        {
          name:'影像报告',
          url:'/imageReport',
          imgUrl:require('../../assets/img/serv0010.png')
        },
        {
          name: '我的地址',
          url: '/address',
          imgUrl: require('../../assets/img/serv008.png')
        },
        {
          name: '账号设置',
          url: '/accountSettings',
          imgUrl: require('../../assets/img/set.png')
        },
        {
          name: '价格公示',
          url: '/pricePublicity',
          imgUrl: require('../../assets/img/serv007.png')
        }
      ]
    }
  },

  methods:{
    routeTo(url){
      this.$router.push(url)
    },
    kefu(){
      this.$dialog.alert({
        message: "联系电话：<a href='tel:021-64879999'>021-64879999</a>",
        closeOnClickOverlay:true
      })
    }
  }

}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
view {
  box-sizing: border-box;
}
.body{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
}

.payment-tips{
  font-size: 26px;
  text-align: left;
  width: 686px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 12px auto 0;
  border-radius: 12px;
  color: #f56c6c;
  background: #fef0f0;
  line-height: 50px;
  height: 100px;
  border: 1px solid #ffe6e6;
}

  .add-button{
    position: relative;
    width: 686px;
    height: 140px;
    text-align: center;
    line-height: 140px;
    margin: 24px auto 0;
    color: #ffffff;
    font-size: 32px;
    background: linear-gradient(180deg, #69BBF1 0%, #3A8AE5 100%);
    border-radius: 12px;
    img{
      position: absolute;
      top: 21px;
      right: 32px;
      width: 97px;
      height: 97px;
    }
    .icon-add{
       font-size: 42px;
       position: relative;
       top: 10px;
       right: 20px;
    }
  }

  .home-in {
    position: relative;
    z-index: 10;
    padding: 12px 32px 24px;
  }


  .entry-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 40px 16px;
    font-size: @font-size-26;
    color: #3d3d3d;
    text-align: center;
    background: #fff;
    border-radius: 12px;

    .entry-item {
      width: 160px;

      .img {
        display: block;
        margin: 0 auto;
        width: 116px;
        height: 116px;
      }
    }
  }
  .card-box{
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 40px 16px 0;
    background: #FFF;
    border-radius: 12px;
    z-index: 2;
    .item{
       width: 120px;
       text-align: center;
       margin-bottom: 40px;
      img{
        width: 48px;
        height: 48px;
        display: block;
        margin: 0 auto;
       }
    }
  }
 .txt{
    text-align: center;
    color: #333333;
    font-size: 24px;
    margin-top: 18px;
  }


  .row-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item{
      width: 213px;
      padding:20px;
      text-align: center;
      background: #FFFFFF;
      border-radius: 12px;
      font-size: 24px;
      box-sizing: border-box;
      img{
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto;
      }
    }
  }


</style>
